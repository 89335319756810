import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {ParametersContext} from "../../contexts/ParametersContext";
import {useAPI} from "../../hooks/APIHook";
import {useDispatch} from "react-redux";
import {notification} from "../../common/reducer/NotificationReducer";
import russian_cards_0 from "../../assets/images/russian_cards_0.png";
import russian_cards_1 from "../../assets/images/russian_cards_1.png";
import world_cards_0 from "../../assets/images/world_cards_0.png";
import world_cards_1 from "../../assets/images/world_cards_1.png";
import {siteApiUrl} from "../../config";

export const DonateComponent = () => {
    const {isMobile} = useContext(ParametersContext);
    const {request} = useAPI();
    const dispatch = useDispatch();

    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const [isCommentVisible, setCommentVisible] = useState(false);
    const [isPromoVisible, setPromoVisible] = useState(false);

    const toggleCommentVisibility = () => setCommentVisible(!isCommentVisible);
    const togglePromoVisibility = () => setPromoVisible(!isPromoVisible);

    useEffect(() => {
        changeReceive().then()
    }, []);

    async function changeReceive() {
        const form = document.querySelectorAll("form")[0];
        const purse = form.elements[0].value;
        const sum = document.getElementById("sum").value;
        const promocode = (document.getElementById("promocode") === null ? "none" : document.getElementById("promocode").value) || "none";

        const fSum = (await request(`${siteApiUrl}/player/calculate?purse=${encodeURIComponent(purse)}&promocode=${encodeURIComponent(promocode)}&sum=${sum}&bonus=1`)).sum || 0;

        document.getElementById("receive").value = `${fSum} ЛК`;
    }

    const fetchSuggestions = async () => {
        if (!searchQuery) {
            setSearchResults([]);
            return;
        }
        try {
            const response = await fetch(`${siteApiUrl}/player/suggestion?nickname=${encodeURIComponent(searchQuery)}`);
            const data = await response.json();
            setSearchResults(data.suggestion || []);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const handleSearch = (input) => {
        setSearchQuery(input);
        fetchSuggestions();
    };

    const handleSelect = (item) => {
        setSearchQuery(item);
    };

    const handlePaymentChange = (event) => {
        const paymentIcons = document.querySelectorAll('.payment-icon-wrapper .payment-icon');

        paymentIcons.forEach((icon) => {
            const input = icon.closest('label').querySelector('input[type="radio"]');
            if (input.checked) {
                icon.src = icon.dataset.checked;
            } else {
                icon.src = icon.dataset.unchecked;
            }
        });
    };


    function blockPattern(e, pattern) {
        if (pattern.test(e.key) && e.key !== "Backspace" && !e.key.includes("Arrow")) e.preventDefault();

        setTimeout(async () => {
            e.target.value = e.target.value.replace(pattern, "");

            await changeReceive();
        }, 100);
    }

    function blockNickname(e) {
        blockPattern(e, /\W/);
    }

    function blockPromocode(e) {
        blockPattern(e, /[\W_]/);
    }

    function blockAmount(e) {
        blockPattern(e, /[^0-9]/);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    async function handleForm(e) {
        const form = document.querySelectorAll("form")[0];
        e.preventDefault();
        const purse = form.elements[0].value;
        const exists = (await request(`${siteApiUrl}/player/exists?nickname=${encodeURIComponent(purse)}`)).exists || false
        if (!exists) {
            dispatch(notification({message: "Такой аккаунт не зарегистрирован"}));
            return
        }

        const paymentType = document.querySelector('input[name="paymentType"]:checked');

        if (!paymentType) {
            dispatch(notification({message: "Выберите способ оплаты"}));
            return
        }

        const email = document.querySelector('input[name="email"]').value;
        if (!validateEmail(email)) {
            dispatch(notification({message: "Некорректный email"}));
            return
        }

        if (paymentType.value === "xsolla")
            if (form.elements[2].value < 35) {
                dispatch(notification({message: "Минимальная сумма платежа: 35 рублей"}));
                return
            }


        form.action = `${siteApiUrl}/${paymentType.value}/create`;
        form.submit();
    }

    return (
        <>
            <Helmet title="Пополнение баланса < DiamondWorld"/>
            <div className="donate">
                {isMobile ? <></> :
                    <div className="information">
                        <div>
                            DiamondWorld<br/>
                            Minecraft сервер
                        </div>
                        <div>
                            Здесь вы можете пополнить свой баланс, который используется для покупки товаров на сервере
                        </div>
                    </div>
                }

                <div>
                    <div>
                        <div>Бонусы!</div>
                        Пополнение от 500р - бонус 5%<br/>
                        Пополнение от 3000р - бонус 10%<br/>
                    </div>

                    <form>
                        <div className="input-group">
                            <label htmlFor="purse">Никнейм:</label>
                            <input
                                name="purse"
                                id="purse"
                                placeholder="Введите ник"
                                onKeyDown={blockNickname}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                name="email"
                                id="email"
                                type="email"
                                placeholder="Введите email"
                            />
                        </div>


                        <div className="input-flex">
                            <div className="input-group">
                                <label htmlFor="sum">Потратите:</label>
                                <input
                                    name="sum"
                                    id="sum"
                                    type="text"
                                    placeholder="Сумма"
                                    defaultValue="500"
                                    required
                                    onKeyDown={blockAmount}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="receive">Получите:</label>
                                <input id="receive" type="text" placeholder="500 ЛК" disabled/>
                            </div>
                        </div>

                        <div className="input-group">
                            {(
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    toggleCommentVisibility();
                                }}>
                                    Добавить комментарий к платежу
                                </a>
                            )}
                            {isCommentVisible && (
                                <>
                                    <label htmlFor="comment">Комментарий к платежу:</label>
                                    <input
                                        name="comment"
                                        id="comment"
                                        placeholder="Комментарий к платежу (если нужен)"
                                        type="text"
                                    />
                                </>
                            )}
                        </div>

                        <div className="input-group">
                            {(
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    togglePromoVisibility();
                                }}>
                                    Ввести промокод
                                </a>
                            )}
                            {isPromoVisible && (
                                <>
                                    <label htmlFor="promocode">Промокод:</label>
                                    <small>Большие буквы кода - обязательны!</small>
                                    <input
                                        name="promocode"
                                        id="promocode"
                                        placeholder="Введите промокод (если есть)"
                                        type="text"
                                        onKeyDown={blockPromocode}
                                    />
                                </>
                            )}
                        </div>

                        <div className="input-group">
                            <label htmlFor="paymentType">Способ оплаты:</label>
                            <div className="payment-methods">
                                <label>
                                    <input
                                        type="radio"
                                        name="paymentType"
                                        value="tbank"
                                        style={{display: "none"}}
                                        onChange={handlePaymentChange}
                                        defaultChecked
                                    />
                                    <div className="payment-icon-wrapper" title="Российские карты">
                                        <img
                                            src={russian_cards_1}
                                            alt="Russian"
                                            data-unchecked={russian_cards_0}
                                            data-checked={russian_cards_1}
                                            className="payment-icon"
                                        />
                                    </div>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="paymentType"
                                        value="xsolla"
                                        style={{display: "none"}}
                                        onChange={handlePaymentChange}
                                    />
                                    <div className="payment-icon-wrapper" title="Оплата другим способом">
                                        <img
                                            src={world_cards_0}
                                            alt="World"
                                            data-unchecked={world_cards_0}
                                            data-checked={world_cards_1}
                                            className="payment-icon"
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                        <button className="btn" type="submit" onClick={handleForm}>
                            Оплата
                        </button>
                    </form>

                </div>
            </div>
        </>
    );
}
